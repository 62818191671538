import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useLibrary, useSDK } from '../hooks/useData.js'

/**
 * Generic component that renders an iframe and receiving window messages from it.
 *
 * @param urlGetter          - a callback to get the iframe url.
 * @param onMessageReceived  - is called once a window message on iframe loaded url is received.
 * @param onFallback         - fallback function to be run once a specific period has passed without messages (Make
 *                           sure that you include the messages check and the timeout logic)
 */
const MessageReceiverIframe = ({
  url,
  onMessageReceived,
  onMessageWaiting,
  onTimeout,
  timeout
}: {
  url: string
  onMessageReceived: (message: { action: string; data: any }) => void
  onMessageWaiting: () => void
  onTimeout?: () => void
  timeout?: number
}) => {
  const library = useLibrary()
  const sdk = useSDK()
  const iframeRef = useRef()

  useEffect(() => {
    if (url) {
      onMessageWaiting()
      const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
        if (
          typeof ev.data === 'string' &&
          // get rid of events sent from frontend itself (frontend imports clientside)
          (ev.origin !== location.origin ||
            !(ev.source as Window).location.href.startsWith(location.origin + library.getPath())) &&
          // make sure that the message comes from this iframe or message comes from pages directly
          (url.startsWith(ev.origin) || ev.origin.startsWith(`https://pages`))
        ) {
          try {
            const json = JSON.parse(ev.data)
            onMessageReceived(json)
          } catch {
            sdk.log('FEAAS: Could not parse iframe message')
          }
        }
      }
      window.addEventListener('message', handler)

      if (onTimeout) {
        setTimeout(() => {
          onTimeout()
        }, timeout)
      }

      return () => window.removeEventListener('message', handler)
    }
  }, [url])

  // avoid opening render host iframe when within pages
  if (url.startsWith('https://xmc-') && location.hostname.includes('pages')) return

  return (
    <Box display={'none'} outline={'1px solid'} outlineColor={'blackAlpha.100'} borderRadius={3} p={3} mt={3}>
      <iframe tabIndex={-1} src={url} width='500px' height='300px' className='' ref={iframeRef}></iframe>
    </Box>
  )
}

export default MessageReceiverIframe
