import React, { createContext, useContext, useEffect, useState } from 'react'
import { ApmBase, init as initApm } from '@elastic/apm-rum'
import { EnvironmentContext, getEnvironment, EnvironmentName } from './EnvironmentProvider.js'

export const ApmContext = createContext({
  apm: null
})

const ApmProvider = ({ children, environment }: { children: any; environment: EnvironmentName }) => {
  const env = useContext(EnvironmentContext)
  const [apm, setApm] = useState<ApmBase>(null)

  if (localStorage['Sitecore.Components.ApmDisabled'] || location.hostname.match(/pages/)) return children

  useEffect(() => {
    setApm(
      initApm({
        serviceName: 'feaas-components-frontend',
        serverUrl: env.apmServer,
        environment: environment,
        active: environment !== 'localhost' && environment !== 'ci'
      })
    )
  }, [])

  useEffect(() => {
    if (!apm) return

    const transaction = apm.startTransaction(location.pathname, 'route-change')
    if (!transaction) return

    const span = transaction.startSpan('my-span')

    setTimeout(() => {
      span.end()
      transaction.end()
    }, 1000)
  }, [location.pathname])

  return <ApmContext.Provider value={{ apm }}>{children}</ApmContext.Provider>
}
export default ApmProvider
